import { useRecoilState } from 'recoil';
import React, { useEffect, useRef} from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import {
  imageType,
} from "../../recoil/atoms";
import '../../styles.css';
import { menuChange } from '../../recoil/atoms';
import CircleIcon from '../../assets/icons/ic_take_picture.svg';
import CloseIcon from '../../assets/icons/ic_close1.svg';
import switchCamera from '../../assets/icons/ic_switch_camera.png';


const AfterImage = () => {
  const location = useLocation();
  const [, setimageValue] = useRecoilState(imageType);
  const [, setComponentChange] = useRecoilState(menuChange);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const recordData = location.state ? location.state.selectedRecordData : null;


  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    getVideo();
  }, [videoRef]);

  const getVideo = () => {
    let video = videoRef.current;
    if (video && !video.srcObject) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: window.innerWidth, height: window.innerWidth } })
        .then(stream => {
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            if (video.readyState >= video.HAVE_ENOUGH_DATA) {
              video.play();
            }
          };
        })
        .catch(err => {
          console.error("error:", err);
        });
    }
  };

  const handleCamera = async () =>{
    try {
      const constraints = { video: { facingMode: "environment" } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          if (video.readyState >= video.HAVE_ENOUGH_DATA) {
            video.play();
          }
        };
        video.width = window.innerWidth;
        video.height = window.innerHeight;
      }
    } catch (error) {
      console.error("Error accessing back camera:", error);
    }

  }

  const takePhoto = () => {

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    // let photo = photoRef.current;
    // let strip = stripRef.current;

    // Draw video frame onto canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0);

    // Get image data URL from canvas
    const dataURL = canvas.toDataURL();
    localStorage.setItem("imageUrl", dataURL);

    setimageValue(dataURL)
    setComponentChange(true)
    navigate(`/aftercapturedimage`, { state: { selectedRecordData: recordData } });

  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black', overflow: 'hidden' }}>
      <div style={{margin:'10px', borderRadius:'8px'}}>
      <video ref={videoRef} style={{width: '100%', height: 'calc(100vh - 50px)',borderRadius:'8px'}} />
      </div>
      <div style={{
        position: 'absolute',
        bottom: '30px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
        <div style={{marginLeft:25, float:'left'}}> 
          <img onClick={handleCamera} src={switchCamera} style={{width:"33px", height:"33px"}}  alt="My Left Image" />
        </div>
        <div style={{ textAlign: 'center' }}>
          <img onClick={takePhoto} src={CircleIcon} style={{ width: 50, height: 50 }} alt="My Center Image" />
        </div>
        <div style={{ float: 'right', marginRight: 25 }}>
          <img onClick={goBack} src={CloseIcon} alt="My Right Image" />
        </div>
      </div>
    </div>
  );
  
}
export default AfterImage;