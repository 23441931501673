import React, { useEffect, useRef } from "react";
import CircleIcon from '../../assets/icons/ic_take_picture.svg';
import CloseIcon from '../../assets/icons/ic_close1.svg';
import GalleryIcon from '../../assets/icons/ic_gallery2.svg';

const MyDocument = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    getVideo();
  }, [videoRef]);

  const getVideo = () => {
    let video = videoRef.current;
    if (video && !video.srcObject) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            if (video.readyState >= video.HAVE_ENOUGH_DATA) {
              video.play();
            }
          };
        })
        .catch(err => {
          console.error("error:", err);
        });
    }
  };

  return (
    <div style={{ position: 'relative', width:"100vw", height:"100vh", backgroundColor:'black'}}>
      <video ref={videoRef} style={{ objectFit: 'cover', width: '100%', height: '100%', maxWidth: '100vh', maxHeight: '100vw', marginTop:100, padding:10 }} />
      <div style={{
        position: 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70%'
      }}>
        <div>
          <img src={GalleryIcon} alt="My Left Image" />
        </div>
        <div style={{ textAlign: 'center' }}>
          <img src={CircleIcon} style={{ width: 50, height: 50 }} alt="My Center Image" />
        </div>
        <div style={{ float: 'right', marginRight: 15 }}>
          <img src={CloseIcon} alt="My Right Image" />
        </div>
      </div>
    </div>
  );
};

export default MyDocument;
