import React, { useState } from "react";
import axios from "axios";
import Services from "../../services/Services";

const EmailForm = () => {
  const [downloaded, setDownloaded] = useState(false);
  const handleDownloadClick = () => {
    const postData = {
      text: "Hello, World!", // Customize this data as needed
    };
    axios
      .post(Services.GENERATE_PPT, postData, {
        responseType: "blob", 
        timeout: 30000// Specify response type as blob
      })
      .then((response) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary link and trigger a click event to download the file
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "presentation.pptx";
        document.body.appendChild(a);
        a.click();

        // Cleanup: revoke the object URL
        window.URL.revokeObjectURL(url);

        // Set a state flag to indicate that the download has completed
        setDownloaded(true);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div>
      {downloaded ? (
        <p>Downloaded successfully!</p>
      ) : (
        <button onClick={handleDownloadClick}>Download Presentation</button>
      )}
    </div>
  );
};

export default EmailForm;
