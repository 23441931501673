import React, { useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { menuChange } from '../../recoil/atoms';
import { useNavigate } from "react-router-dom";
import Services from "../../services/Services";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const UploadImages = () => {
  const navigate = useNavigate();
  const [currentFile, setCurrentFile] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const componentChange = useRecoilState(menuChange);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const imageInfos = [];
  const [isError, setIsError] = useState(false);
  const [description, setDescription] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const kaizenData = JSON.parse(localStorage.getItem("kaizenData"));

  const selectFile = (e) => {
    setCurrentFile(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    console.log("previewImage", previewImage);
    setProgress(0);
    setMessage("");
    setIsError(false);
  };

  const handleChange = (event) => {
    setDescription(event.target.value);
  }

  const upload = () => {
    setProgress(0);
    setIsError(false);
    setMessage("");
    const formData = new FormData();
    const serialNumber = kaizenData.serial_number;
    const getLastNumber = serialNumber.substring(serialNumber.lastIndexOf('/') + 1); // returns "0623_001"
    let fileName = '';
    if (componentChange) {
      fileName = 'after' + "_" + getLastNumber;
    } else {
      fileName = 'before' + "_" + getLastNumber;
    }
    console.log(fileName, "fileName")
    const modifiedName = fileName + "-" + currentFile.name;
    const newFile = new File([currentFile.slice()], modifiedName, { type: currentFile.type });
    formData.append("file", newFile);


    axios.post(Services.IMAGEUPLOAD, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      }
    })
      .then(function (response) {
        // handle success
        console.log("RESPONSEEEBEFORE", response.data);
        getImages(getLastNumber)


      })
      .catch(function (error) {
        console.log("ERROR", error)
      });
  }


  const getImages = (number) => {
    axios.get(Services.GET_DOWNLOAD_IMAGES,)
      .then(function (response) {
        // handle success
        console.log("RESPONSEEEBeforesavedImages", response.data[1].name);
        const dataArray = response.data;

        const beforeImages = dataArray.filter(image => image.name.includes('before'));
        const afterImages = dataArray.filter(image => image.name.includes('after'));
        console.log(number, "number")
        const filteredBeforeImages = beforeImages.filter(image => image.name.includes(number));
        console.log(filteredBeforeImages, "filterimages");
        const filteredAfterImages = afterImages.filter(image => image.name.includes(number));
        getImageData(filteredBeforeImages, filteredAfterImages);
        navigate(`/kaizen`);

      })
      .catch(function (error) {
        console.log("ERROR", error)
      }
      );
  }

  const getImageData = (beforeImage, afterImage) => {
    if (componentChange) {
      insertAfterStatus(afterImage)
    } else {
      insertNewRecord(beforeImage);
    }
  }

  const insertAfterStatus = async (afterImage) => {
    console.log("kaizendatarecordid", kaizenData.record_id)
    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append('after_image', JSON.stringify(afterImage));
      formData.append('created_at', new Date().toISOString());
      formData.append('updated_at', new Date().toISOString());
      formData.append('date', new Date().toISOString());
      formData.append('description', description);
      formData.append('record_id', kaizenData.record_id);
      // Define the record_id to be used in the URL
      const record_id = kaizenData.record_id;
      // Make the POST request using Axios
      const response = await axios.post(Services.INSERT_AFTER_STATUS + record_id, formData);
      console.log("responseAfter", response.data); // Log the response data

      // Handle the response here (e.g. update state, display a success message)
    } catch (error) {
      console.error(error);
      // Handle the error here (e.g. display an error message)
    }
  };

  const insertNewRecord = async (beforeImage) => {
    try {
      const formData = new FormData();
      formData.append('plant_id', kaizenData.plant_id);
      formData.append('wc_name', kaizenData.wc_name);
      formData.append('serial_number', kaizenData.serial_number);
      formData.append('opening_date', kaizenData.opening_date);
      formData.append('kaizen_type', kaizenData.kaizen_type);
      formData.append('user_id', userData.id);
      formData.append('updated_at', new Date().toISOString());
      formData.append('kaizen_name', userData.kaizen_name);
      const response = await axios.post(Services.INSERT_NEW_KAIZEN, formData);
      insertBeforeStatus(response.data.id, beforeImage)
    } catch (err) {
      console.error(err);
      // Handle the error as needed
    }
  }
  const insertBeforeStatus = async (id, beforeImage) => {
    console.log("idAfterstatus", id);

    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append('before_image', JSON.stringify(beforeImage));
      formData.append('created_at', new Date().toISOString());
      formData.append('updated_at', new Date().toISOString());
      formData.append('date', new Date().toISOString());
      formData.append('description', description);
      formData.append('record_id', id);

      // Define the record_id to be used in the URL
      const record_id = id;

      // Make the POST request using Axios
      const response = await axios.post(Services.INSERT_BEFORE_STATUS + record_id, formData);
      console.log("response", response);
    } catch (error) {
      console.error(error);
      // Handle the error here (e.g. display an error message)
    }
  };

  return (
    <div className="mg20">
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={selectFile} />
        <Button
          variant="outlined"
          style={{ textTransform: "none", marginTop: 30 }}
          component="span">
          Choose Image
        </Button>
      </label>
      <div style={{ marginTop: 30 }}>
        {currentFile ? currentFile.name : null}
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 30, alignItems: 'center', justifyContent: 'center' }}>
        <TextField
          key="Description"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="off"
          value={description}
          onInput={(e) => {
            setDescription(e.target.value);
          }}
          onChange={handleChange}
          multiline={true}
          rows={5}
          style={{
            width: '100%', // Set width to 80% for screens wider than 600 pixels
            '@media (max-width: 600px)': { // Set width to 100% for screens smaller than 600 pixels
              width: '100%'
            }
          }}
        />
      </div>

      <Button
        className="btn-upload"
        color="primary"
        variant="contained"
        style={{ marginTop: 40 }}
        component="span"
        disabled={!currentFile}
        onClick={upload}>
        Upload
      </Button>

      {currentFile && (
        <Box style={{ marginTop: 30 }} display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
          </Box>
        </Box>
      )}

      {previewImage && (
        <div>
          <img className="preview my20" src={previewImage} alt="" />
        </div>
      )}

      {message && (
        <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
          {message}
        </Typography>
      )}

      <Typography variant="h6" className="list-header">
        List of Images
      </Typography>
      <ul className="list-group">
        {imageInfos &&
          imageInfos.map((image, index) => (
            <ListItem
              divider
              key={index}>
              <img src={image.url} alt={image.name} height="80px" className="mr20" />
              <a href={image.url}>{image.name}</a>
            </ListItem>
          ))}
      </ul>
    </div >
  );
};

export default UploadImages;
