import React, { useState, useRef, useEffect} from 'react';
import {
  Typography,
  Button
} from "@mui/material";
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from "react-router-dom";
import CloudUpload from '../../assets/icons/ic_cloud_upload.svg';
import {imageType,afterImageDescriptionState} from "../../recoil/atoms";
import ImageSlider from "../../input/ImageSlider";
import Text from '../../input/Text';
import axios from 'axios';
import Services from '../../services/Services';
import AlertDialog from '../../input/AlertDialog';

const AfterKaizenSelection = () => {
  const navigate = useNavigate();
 
  const [afterImageDescription, setAfterImageDescription] = useRecoilState(afterImageDescriptionState);
  const location = useLocation();
  const [imageValue, setimageValue] = useRecoilState(imageType);
  const [error, setError] = useState('');
  const recordData = location.state ? location.state.selectedRecordData : null;
  const kaizenName = recordData?.kaizen_name ?? '';
  const plantName = recordData?.plant_name ?? '';
  const workCenterName = recordData?.work_center_name ?? '';
  const kaizenTypeName = recordData?.kaizen_type ?? ''; 
  const fileInputRef = useRef(null); // Create a ref for the file input element  
  const [parsedData, setParsedData] = useState(null);
  const [description, setDescription] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));



  useEffect(() => {
    if (recordData.before_status && recordData.before_status.length > 0) {
      const beforeData = recordData.before_status[0];
      const parsedData = JSON.parse(beforeData.before_image);
      const description = beforeData.description;
      setParsedData(parsedData);
      setDescription(description);
    } else {
      console.error('before_status is undefined or empty');
    }
  }, [recordData])

  const handlePopover = (event) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleKeyPress = (event) => {
    // Handle key press events, e.g., pressing Enter or Space
    if (event.key === 'Enter' || event.key === ' ') {
      handlePopover();
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      setAfterImageDescription("");
    };
    fetchData();
  }, []);
  
  const handleChangeImprovement = (event) => {
    const newValue = event.target.value;
    setAfterImageDescription(newValue);
  };


    const handleFileSelect = (event) => {
      const files = event.target.files; // Get the selected files from the input
      const imageUrls = []; // Array to store the URLs of selected images
    
      // Example: Display the selected images
      for (const file of files) {
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          imageUrls.push(imageUrl); // Store the URL in the array
      
          // Display the image
          const image = new Image();
          image.src = imageUrl;
          document.body.appendChild(image);
      
          // Log the first image URL
          console.log("Selected image URLs:", imageUrls[0]);
          setimageValue(imageUrls[0]);
          navigate(`/aftercapturedimage`, { state: { selectedRecordData: recordData } });
        };
      
        reader.readAsDataURL(file); // Read the selected file as a data URL
      }
      
    };
  

  const handleClick = async() => {
    let commonError = '';
    if (!afterImageDescription && !imageValue) {
      commonError = 'Please provide an improvement and select an image before updating.';
    }
     else if (!afterImageDescription) {
      commonError = 'Please fill an Improvement';
    } else if (!imageValue) {
      commonError = 'Please select an image before updating.';
    }
    setError(commonError);

    if (!commonError) {
      await updateAfterStatus();
    }
  }


  const updateAfterStatus = async () => {
    const recordId = recordData.id;

    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append('updated_at', new Date().toISOString());
      formData.append('description', afterImageDescription);
      formData.append('kaizen_id', recordId);
      formData.append('user_id', userData.id);

      // Make the POST request using Axios
      const response = await axios.put(Services.UPDATE_AFTER_STATUS + recordId, formData);
      if (response.status === 200) {
        navigate(`/kaizen`);
      }
      // Handle the response here (e.g. update state, display a success message)
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
      // Handle the error here (e.g. display an error message)
    } 
  };


  const handleCancelClick =  () => {
   navigate(-1);
  }


  return (
    <div style={{padding:20}}> 
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>After Kaizen</Typography>
        <Typography style={{ textAlign: 'left' }}>Prior to Kaizen, Sekurit,India </Typography>
      </div>

      <div>
         <div style={{ marginTop: "30px" }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Name"
            value={kaizenName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30, }}>
          <Text
            style={{ width: "100%", textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Location"
            value={plantName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Work Center"
            value={workCenterName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Serial Number"
            value={recordData.serial_number}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
        <Text
              style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
              id="outlined-read-only-input"
              label="Kaizen Type"
              value={kaizenTypeName}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
            />
            </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
        <Text
            style={{ width: "100%", marginTop: 30, textAlign: "left" }}
            id="date"
            label="Date"
            type="date"
            variant="outlined"
            value={recordData.opening_date}
            InputLabelProps={{
              shrink: true,
            }}
            />
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600 }}>Before Image</Typography>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Observations</Typography>
        <Typography style={{ textAlign: 'left' }}>{description}</Typography>
        {parsedData && parsedData.length > 0 && (
            <ImageSlider images={parsedData.map(image => image.data_url)} />
          )}
      </div>

      <div style={{ marginTop: "30px" }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600, marginBottom:"20px" }}>After Image</Typography>
          <Text
            required
            fullWidth
            label="Improvements *"
            name="improvements"
            autoComplete="off"
            value={afterImageDescription}
            onChange={handleChangeImprovement}          
            multiline={true}
            rows={5}
          />
          {error && <Typography style={{ color: 'red', fontSize: 14 }}>{error}</Typography>}

        </div>
      <div style={{
        border: "2px dashed #00448B",
        padding: "20px",
        flex: 1,
        display: 'flex',
        width: "100%",
        height: 300,
        marginTop: 30,
        backgroundColor: '#00448a1a',
        marginRight: 20,
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "4px"
      }}>
      <button
          onClick={handlePopover}
          onKeyDown={handleKeyPress}
          style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
            cursor: 'pointer',
            padding: 0,  // Remove padding to ensure image is not covered
            border: 'none',  // Remove border to ensure image is not covered
            background: 'none',  // Remove background to ensure image is not covered
          }}
          aria-label="Upload File"
        >
          <img
            src={CloudUpload}
            alt="Upload File"
            style={{ width: '100%', height: '100%' }}
          />
        </button>

        <Typography onClick={handlePopover} style={{
          textAlign: 'center',
          marginLeft: 15,
          color: 'black',
          fontWeight: 600,
          width: 170,
          fontSize: 16
        }}>Click to Capture/Upload</Typography>
         <input
        type="file"
        ref={fileInputRef} // Assign the input element to the ref
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        accept="image/*" 
      />
          {error && <Typography style={{ color: 'red', fontSize: 14 }}>{error}</Typography>}
      </div>
      <div style={{ margin: 'auto', width: "350px", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            width: "45%",
            borderRadius: '3px',
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: "#00448B",
            marginTop: "10px",
            textTransform: 'none',
            marginRight: 30
          }}
         onClick={handleCancelClick}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          style={{
            width: "45%",
            borderRadius: '3px',
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            marginLeft: '5'
          }}
          onClick={handleClick}
        >
          Update
        </Button>
      </div>
    </div>
  )
}
export default AfterKaizenSelection;
