import React from 'react';
import { TextField, } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    "& .MuiSelect-root": {
      color: "#1D1B20",
    },
    "& .MuiInputLabel-root": {
      color: "#49454F",
      padding: '0 8px', // Add some padding to avoid overlapping with outlined border
      backgroundColor: 'white', // Set the background color to match the form control background
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#00448B",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747E",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00448B",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red", // Red border on error
    },

    
  },
  textEdit: {
    "& .MuiSelect-root": {
      color: "#1D1B20",
    },
    "& .MuiInputLabel-root": {
      color: "#49454F",
      padding: '0 8px', // Add some padding to avoid overlapping with outlined border
      backgroundColor: 'white', // Set the background color to match the form control background
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#00448B",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00448B",
      borderWidth:"3px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00448B",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red", // Red border on error
    },
  },
}));

const Text = (props) => {
  const classes = useStyles();
  return (
      <TextField
        id={props.id}
        label={props.label}
        fullWidth
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        type={props.type ? props.type : 'text'}
        inputRef={props.inputRef}
        InputLabelProps={{
          shrink: true
        }}
        multiline={props.multiline}
        disabled={props.disabled} 
        rows={props.rows}
        className={`${props.isKaizenEdit ? classes.textEdit : classes.text}`}
      />
  );
};
export default Text;
