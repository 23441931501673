import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button
} from "@mui/material";
import Text from "../../input/Text";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { isUpdateAfterStatus, afterImageDescriptionState, imageType } from '../../recoil/atoms';
import ImageSlider from "../../input/ImageSlider";
import axios from "axios";
import Services from "../../services/Services";
import BlobAPI from "../../services/BlobAPI";
import CloudUpload from '../../assets/icons/ic_cloud_upload.svg';
import NavDrawer from "../../app/LeftDrawer";
import SuccessDialog from "../../input/SuccessDialog";
import DeleteDialog from "../../input/DeleteDialog";
import AlertDialog from "../../input/AlertDialog";


const AfterKaizen = () => {
  const navigate = useNavigate();
  const [, setIsUpdate] = useRecoilState(isUpdateAfterStatus);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isKaizenEdit, setIsKaizenEdit] = useState(false);
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [, setimageValue] = useRecoilState(imageType);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);

  const recordData = location.state ? location.state.selectedRecordData : null;
  const kaizenName = recordData?.kaizen_name ?? '';
  const plantName = recordData?.plant_name ?? '';
  const workCenterName = recordData?.work_center_name ?? '';
  const kaizenTypeName = recordData?.kaizen_type ?? '';
  let beforeData, afterData;
  let parsedData = [];
  let parsedAfterData = [];
  if (recordData) {
    beforeData = recordData.before_status[0];
    afterData = recordData.after_status[0];

    console.log("beforeData", beforeData);
    console.log("afterData", afterData);


    parsedData = beforeData?.before_image && typeof beforeData.before_image === 'object'
      ? beforeData.before_image
      : JSON.parse(beforeData.before_image || '[]');

    // Check if after_image is already an object
    parsedAfterData = afterData?.after_image && typeof afterData.after_image === 'object'
      ? afterData.after_image
      : JSON.parse(afterData.after_image || '[]');
  }

  // Now, afterImages may contain the parsed data or an empty array if there was a null condition.
  const [afterImages, setAfterImages] = useState(parsedAfterData);
  const [afterImageDescription, setAfterImageDescription] = useRecoilState(afterImageDescriptionState);

  useEffect(() => {
    if (afterData.description) {
      setAfterImageDescription(afterData.description);
    }
  }, [afterData]);


  const handleUpdateClick = async () => {
    if (isKaizenEdit) {
      await updateAfterStatus(afterImages);
    }
  }

  const onClickFunction = () => {
    // Implement the function you want to call in ComponentB
    console.log('Function in ComponentB was called.');
    setDeleteRow(true);
  };

  const rowDeleteKaizen = async () => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    let id = recordData.id;
    for (const image of parsedData) {
      const imageName = image.image_name;
      deleteExistingImageFromBlob(imageName);
    }
    for (const image of afterImages) {
      const imageName = image.image_name;
      deleteExistingImageFromBlob(imageName);
    }
    try {
      const response = await axios.delete(`${Services.DELETE_NEW_KAIZEN}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Assuming access_token is available
          secret_key: secret_key // Assuming secret_key is available
        }
      });

      if (response.data) {
        console.log("data", response.data);
        setShowDialog(true);
        onHandleDeleteClosed();
        navigate(`/kaizen`);
      }
    }
    catch (error) {
      console.log("ERROR", error)
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    }
  }

  const onHandleClosed = () => {
    setShowDialog(false);
  }
  const onHandleDeleteClosed = () => {
    setDeleteRow(false);
  }

  const updateAfterStatus = async (afterImage) => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    const recordId = recordData.id;
    console.log("recordId", recordId);
    try {
      // Define the data to be sent in the request body
      const formData = new FormData();
      formData.append('after_image', JSON.stringify(afterImage));
      formData.append('updated_at', new Date().toISOString());
      formData.append('description', afterImageDescription);
      formData.append('kaizen_id', recordId);
      formData.append('user_id', userData.id);
      // Make the POST request using Axios
      const response = await axios.put(
        Services.UPDATE_AFTER_STATUS + recordId,
        formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          secret_key: secret_key
        }
      }
      );
      if (response.status === 200) {
        setShowDialog(true);
        setAfterImages(afterImage);
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        const { status } = error.response;
        // Handle specific error codes or messages as needed
        if (status === 429) {
          AlertDialog({
            type: "warning",
            title: "Warning",
            text: "Too Many Requests Submitted, Please try again..",
          });
        }
      } else {
        // Handle other types of errors
        console.log("Other error:", error);
      }
    } finally {
      setAfterImages(afterImage);
    }
  };
  const handleEditClick = () => {
    setIsKaizenEdit(true)
  }

  const handleDeleteIconClick = (index) => {

    if (index >= 0 && index < afterImages.length) {
      const imageName = afterImages[index].image_name;

      deleteExistingImageFromBlob(imageName);

      // Use the setState callback to ensure the state is updated before calling updateBeforeStatus
      setAfterImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.splice(index, 1);
        updateAfterStatus(updatedImages);
        return updatedImages;
      });
    } else {
      console.error('Invalid index:', index);
    }
  }

  const deleteExistingImageFromBlob = async (imageName) => {
    try {
      await BlobAPI.deleteBlobImageAPI(imageName, userData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAddIconClick = () => {
    if (afterImages.length < 4) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      alert("You can only add up to 4 images.");
    }
  };

  const handleChangeImprovement = (event) => {
    const newValue = event.target.value;
    setAfterImageDescription(newValue);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files; // Get the selected files from the input
    // Log the selected files
    const imageUrls = []; // Array to store the URLs of selected images

    // Example: Display the selected images
    for (const file of files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageUrl = e.target.result;
        imageUrls.push(imageUrl); // Store the URL in the array

        // Display the image
        const image = new Image();
        image.src = imageUrl;
        document.body.appendChild(image);

        // Log the first image URL
        setimageValue(imageUrls[0]);
        setIsUpdate(true);
        navigate(`/aftercapturedimage`, { state: { selectedRecordData: recordData } });
      };

      reader.readAsDataURL(file); // Read the selected file as a data URL
    }
  };

  const handleOpenPopover = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      {(userData.role_id === 5 && <NavDrawer onClickFunction={onClickFunction} showDeleteIcon={true} />)}
      <SuccessDialog open={showDialog} onClose={onHandleClosed} msg={isKaizenEdit ? "New Kaizen Updated Successfully" : "New Kaizen Deleted Successfully"} />
      <DeleteDialog open={deleteRow} onClose={onHandleDeleteClosed} handleDelete={() => rowDeleteKaizen()} />
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>After Kaizen</Typography>
        <Typography style={{ textAlign: 'left' }}>Following, Kaizen will enter Sekurit India.</Typography>
      </div>
      <div>
        <div style={{ marginTop: "30px" }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Name"
            value={kaizenName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30, }}>
          <Text
            style={{ width: "100%", textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Location"
            value={plantName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Work Center"
            value={workCenterName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Serial Number"
            value={recordData.serial_number}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Type"
            value={kaizenTypeName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: "left" }}
            id="date"
            label="Date"
            type="date"
            variant="outlined"
            value={recordData.opening_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600 }}>Before Kaizen</Typography>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Observations</Typography>
        {recordData?.observation && (
          <Typography style={{ textAlign: 'left' }}>{recordData.observation}</Typography>
        )}        <div style={{ marginTop: "30px" }}>
          <ImageSlider
            images={parsedData.map(image => image.data_url)}
          />
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 25, fontWeight: 600, marginBottom: "20px" }}>After Kaizen</Typography>
        <Text
          required
          fullWidth
          label="Improvements"
          name="improvement"
          autoComplete="off"
          value={afterImageDescription}
          onChange={isKaizenEdit ? handleChangeImprovement : null}
          multiline={true}
          rows={5}
          isKaizenEdit={isKaizenEdit}
        />
        <div style={{ marginTop: "30px" }}>
          {afterImages.length > 0 ? (
            <div>
              <ImageSlider
                images={afterImages.map((image) => image.data_url)}
                onAddIconClick={isKaizenEdit ? handleAddIconClick : null}
                onDeleteIconClick={isKaizenEdit ? handleDeleteIconClick : null}
                isKaizenEdit={isKaizenEdit}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  border: "2px dashed #00448B",
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  height: 300,
                  marginTop: 30,
                  backgroundColor: "#00448a1a",
                  marginRight: 20,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >
                <button
                  onClick={handleOpenPopover}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleOpenPopover();
                    }
                  }}
                  style={{
                    border: 'none',          // Remove border
                    padding: '0',            // Remove padding
                    margin: '0',             // Remove margin
                    backgroundColor: 'transparent',  // Set background color to transparent
                    cursor: 'pointer',       // Add a pointer cursor to indicate it's clickable
                    outline: 'none',         // Remove outline on focus
                  }}
                >
                  <img
                    src={CloudUpload}
                    alt="My Image"
                    style={{ width: '30px', height: '30px' }}  // Set the desired width and height
                  />
                </button>
                <Typography
                  onClick={handleOpenPopover}
                  style={{
                    textAlign: "center",
                    marginLeft: 15,
                    color: "black",
                    fontWeight: 600,
                    width: 170,
                    fontSize: 16,
                  }}
                >
                  Click to Capture/Upload
                </Typography>
              </div>
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
            multiple
          />
        </div>

      </div>

      <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            borderRadius: '3px',
            width: "47%",
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            display: 'flex',
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: "#00448B",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            width: "48%",
            borderRadius: '3px',
            flex: 1,
            display: 'flex',
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={isKaizenEdit ? handleUpdateClick : handleEditClick}
        >
          {isKaizenEdit ? "Update" : "Edit"}
        </Button>
      </div>
    </div>
  )
}
export default AfterKaizen;
