import React from 'react';
import TeamsIcon from '../../assets/icons/ic_teams.svg';
import MailIcon from '../../assets/icons/ic_mail.svg';
import {Typography } from "@mui/material";
const HelpSupport = () => {
    return(
        <div style={{justifyContent:'center', alignItems:'center', display:"flex",flexDirection:"column",height:"100vh"}}>
        <div style={{
            width: "295px",
            height:"40vh",
            border: "1px solid rgba(0, 0, 0, 0.14)",
            display:'flex',
            flexDirection:'column',
            justifyContent:"center",
            alignItems:"center",
        }}>
            <img src={TeamsIcon}></img>
            <Typography  align="center" style={{width:177,color:"#101010",fontSize:"20px",fontWeight:700}}>Talk to our Supprt Engineers</Typography>
            <Typography  align="center" style={{width:280,color:"#585757",fontSize:"16px",marginTop:10}}>Let us know your difficulties with the platform.  We’re happy to help! :)</Typography>
        </div>
       
        <div style={{
            width: "295px",
            height:"40vh",
            border: "1px solid rgba(0, 0, 0, 0.14)",
            display:'flex',
            flexDirection:'column',
            justifyContent:"center",
            alignItems:"center",
            marginTop:30
        }}>
            <img src={MailIcon}></img>
            <Typography  align="center" style={{width:177,color:"#101010",fontSize:"20px",fontWeight:700,marginTop:10}}>Write to us</Typography>
            <Typography  align="center" style={{width:280,color:"#585757",fontSize:"16px",marginTop:10}}>Request a demo or get started with implementing Empower Ops at your plant.</Typography>
        </div>
       
      </div>
    )
}
export default HelpSupport;