import { Button } from '@mui/material';
import NewIcon from '../../assets/icons/ic_plus_white.svg';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core'
import BeforeKaizenTab from '../kaizens/BeforeKaizenTab';
import AfterKaizenTab from '../kaizens/AfterKaizenTab';
import React, { } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  customStyleOnTab: {
    fontSize: '15px',
    color: '000000',
    fontWeight: '400',
    textTransform: 'none',
    marginRight: "30px"

  },
  customStyleOnActiveTab: {
    color: 'blue'
  },
  activeTab: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000000',
    textTransform: 'none',
    marginRight: "30px"

  }
})
const Kaizen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = parseInt(queryParams.get('tab')) || 0;
  const classes = useStyles();
  const [value, setValue] = React.useState(initialTab);

  const handleNewClick = () => {
    navigate(`/home`);
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "20px" }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600, marginLeft: "15px" }}>Kaizens</Typography>
        <Button
          variant="contained"
          style={{
            width: "107px",
            height: "40px",
            borderRadius: '3px',
            marginRight: "15px",
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            marginLeft: '5px'
          }}
          onClick={handleNewClick}
        >
        <img
          src={NewIcon}
          style={{ marginRight: 10, cursor: 'pointer' }} // Add a pointer cursor to indicate it's clickable
          alt="button icon"
          role="img" // Add a role attribute to indicate it's an image
          aria-label="Button Icon" // Add an aria-label for accessibility
        />New
        </Button>
      </div>

      <div style={{ marginTop: 20 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Tabs value={value} onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#8F908F",
                  fontWeight: 500,
                }
              }}
              aria-label="basic tabs example">
              <Tab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>Before Kaizen</span>} />
              <Tab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>After Kaizen</span>} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BeforeKaizenTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AfterKaizenTab />
          </TabPanel>
        </Box>
      </div>

    </div>
  )
}
export default Kaizen;