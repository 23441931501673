import React from 'react';
import Text from '../../input/Text';

const ProfileView = () => {

    const userData = JSON.parse(localStorage.getItem("userData"));

    let roleName = '';
     if(userData.role_id === 1){
        roleName = "Admin"
     }else if(userData.role_id === 2){
       roleName = "Plant Manager";
     }else if(userData.role_id === 3){
        roleName = "Shift Manager"
     }else if(userData.role_id === 4){
        roleName = "Operator";
     }else{
       roleName = "WCM Coordinator";
     }
     let plantName = "";
     if(userData.plant_id === 1){
        plantName = "Chennai";
     }else if(userData.plant_id === 2){
        plantName = "Chakan";
     }else if(userData.plant_id === 3) {
        plantName = "Bhiwadi";
     }else{
      plantName = "All (Chennai, Chakan, Bhiwadi)";
     }
    return (
      <div style={{padding:"20px"}}> 
         <div style={{marginTop:"30px"}}>
          <Text
            style={{ width: "100%", marginTop: 30 }}
            id="name"
            label="First Name"
            value={userData.first_name}
          />
        </div>
        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30 }}
            id="name"
            label="Last Name"
            value={userData.last_name}
          />
        </div>
        {/* Rest of the form fields */}
        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30, }}
            id="sgid"
            label="SG ID"
            value={userData.sgid}
             >
          </Text>
        </div>
        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30, }}
            id="emaild"
            label="E-Mail Id"
            value={userData.email_id}
        >
          </Text>
        </div>

        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30, }}
            id="role"
            label="Role"
            value={roleName}
        >
          </Text>
        </div>

        
        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30, }}
            id="business"
            label="Business"
            value="Sekurit"
        >
          </Text>
        </div>

        <div style={{marginTop:"30px"}}> 
          <Text
            style={{ width: "100%", marginTop: 30, }}
            id="plant"
            label="Plant"
            value={plantName}
         >
          </Text>
        </div>

      </div>
    );
  
}

export default ProfileView;