import Card from "@material-ui/core/Card";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CircularProgress, Avatar } from "@material-ui/core"; // Assuming you are using Material-UI
import API from "../../services/API";
import SearchIcon from "../../assets/icons/ic_search.svg";
import PlaceHolderIcon from "../../assets/icons/ic_placeholder.svg";
import BlobAPI from "../../services/BlobAPI";

const BeforeKaizenTab = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visibleRecords, setVisibleRecords] = useState(10);
  const [recordData, setRecordData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredSearchRecords, setFilteredSearchRecords] = useState([]);
  // const [allProcessedImages, setAllProcessedImages] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      await getNewRecordData();
    };
    fetchData();
  }, []);

  const avatarContainerStyle = {
    display: "flex",
    padding: "4px 3px 4px 4px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    background: "#D7EDFF",
    color: "#0452C8",
  };



  const getNewRecordData = async () => {
    try {
      setLoading(true);

      let newRecord;

      if (userData.role_id === 4) {
        newRecord = await API.getNewKaizenData(userData);
      } else if (userData.role_id === 5 || userData.role_id === 3) {
        newRecord = await API.getNewKaizenDataWithPlantId(userData);
      } else {
        newRecord = await API.getOverallKaizenData(userData);
      }
      if (newRecord && newRecord.length > 0) {
        
       // Sorting newRecord by created_at
       newRecord.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        const firstTenRecords = newRecord.slice(0, 10);
        const imageNamesArray = [];
        console.log("First ten records:", firstTenRecords);
        firstTenRecords.forEach(record => {
          record.before_status.forEach(status => {
            const beforeImages = JSON.parse(status.before_image);
            beforeImages.forEach(image => {
              imageNamesArray.push(image.image_name);
            });
          });
        });
        console.log("imageNamesArray", imageNamesArray);
        const imageNamesArrayLength = imageNamesArray.length;
        const response = await BlobAPI.downloadBlobImage(imageNamesArray, imageNamesArrayLength, userData);
        newRecord.forEach(item => {
          if (item.before_status && item.before_status.length > 0) {
            const beforeStatus = item.before_status[0];
            const beforeImages = JSON.parse(beforeStatus.before_image);

            beforeImages.forEach((image) => {
              const imageData = response.find(data => data.image_name === image.image_name);
              if (imageData) {
                image.data_url = imageData.data_url;
              }
            });
            beforeStatus.before_image = beforeImages;
          }
        });

        setRecordData(newRecord);
      } else {
        setRecordData([]); // Set recordData to an empty array if newRecord is empty
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }



  const handleClickSeeMore = async () => {
    try {
      setLoading(true);
      const nextVisibleIndex = visibleRecords + 10;
      // Assuming newRecord is accessible, or you need to fetch it again or keep it in state
      const nextTenRecords = recordData.slice(visibleRecords, nextVisibleIndex);
      const imageNamesArray = [];

      nextTenRecords.forEach(record => {
        record.before_status.forEach(status => {

          let beforeImages;

          if (Array.isArray(status.before_image)) {
            beforeImages = status.before_image;
          } else {
            // Parse status.before_image if it's a string
            try {
              beforeImages = JSON.parse(status.before_image);
            } catch (error) {
              console.error("Error parsing before_images JSON:", error);
            }
          }
          console.log("Before images:", beforeImages);
          // Do whatever you want with the before_images here
          beforeImages.forEach(image => {
            imageNamesArray.push(image.image_name);
          });
        });
      });

      const imageNamesArrayLength = imageNamesArray.length;
      const response = await BlobAPI.downloadBlobImage(imageNamesArray, imageNamesArrayLength, userData);

      nextTenRecords.forEach(item => {
        if (item.before_status && item.before_status.length > 0) {
          const beforeStatus = item.before_status[0];

          let beforeImages;

          // Check if beforeStatus.before_image is already an array
          if (Array.isArray(beforeStatus.before_image)) {
            beforeImages = beforeStatus.before_image;
          } else {
            // Parse beforeStatus.before_image if it's a string
            try {
              beforeImages = JSON.parse(beforeStatus.before_image);
            } catch (error) {
              console.error("Error parsing beforeStatus.before_image JSON:", error);
              return; // Skip further processing for this beforeStatus
            }
          }

          beforeImages.forEach((image) => {
            const imageData = response.find(data => data.image_name === image.image_name);
            if (imageData) {
              image.data_url = imageData.data_url;
            }
          });
          beforeStatus.before_image = beforeImages;
        }
      });

      // Update your state with the new records appended
      setRecordData(prevRecords => {
        const newRecordsToAdd = nextTenRecords.filter(newRecord =>
          !prevRecords.some(prevRecord => prevRecord.id === newRecord.id));
        return [...prevRecords, ...newRecordsToAdd];
      });
      setVisibleRecords(visibleRecords + 10);
      console.log("recordDaaaa", recordData);

    } catch (error) {
      console.error('Error in handleClickSeeMore:', error);

    } finally {
      setLoading(false);
    }
  }

  // const handleClickSeeMore = async () => {
  //   try {
  //     setVisibleRecords(visibleRecords + 10);

  //   } catch (error) {
  //     console.error('Error in handleClickSeeMore:', error);
  //   }
  // }

  // if (newRecord) {
  //   newRecord.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  //   images = [];

  //   newRecord.forEach(item => {
  //     if (item.before_status && item.before_status.length > 0) {
  //       const beforeImages = JSON.parse(item.before_status[0].before_image);
  //       beforeImages.forEach(image => {
  //         images.push({ image_name: image.image_name });
  //       });
  //     }
  //   });

  //   console.log("images", images);

  //   processedImageIndex = loadedImages ? loadedImages : processedImageIndex;

  //   // Calculate the range of images to pass to the API
  //   const imagesToProcess = images.slice(processedImageIndex, processedImageIndex + 10);
  //   console.log("Downloading images:", imagesToProcess); // Added logging
  //   console.log(processedImageIndex, loadedImages)
  //   // Download blob images and extract data_url
  //   const response = await BlobAPI.downloadBlobImage(imagesToProcess, "before");
  //   console.log("Response from BlobAPI:", response); // Added lo
  //   // Extract data_url from response and update before_status
  //   const dataURLs = response.map((res, index) => ({
  //     image_name: imagesToProcess[index].image_name,
  //     data_url: res.data_url
  //   }));
  //   console.log("Extracted Data URLs:", dataURLs);  

  //   const allProcessedImageData = prevImages => [...prevImages, ...dataURLs];
  //   const processedData = allProcessedImageData(allProcessedImages).length === 0 ? dataURLs : allProcessedImageData(allProcessedImages);

  // setAllProcessedImages(processedData);
  //   newRecord.forEach(item => {
  //     if (item.before_status && item.before_status.length > 0) {
  //       const beforeStatus = item.before_status[0];
  //       const beforeImages = JSON.parse(beforeStatus.before_image);

  //       beforeImages.forEach((image) => {
  //         const imageData = processedData.find(data => data.image_name === image.image_name);
  //         if (imageData) {
  //           image.data_url = imageData.data_url;
  //         }
  //       });
  //       beforeStatus.before_image = beforeImages;
  //     }
  //   });

  // setRecordData(newRecord);
  // return newRecord;

  // } catch (error) {
  //   setError(error.message);
  // } finally {
  //   setLoading(false);
  // }
  // }
  // };


  // const handleClickSeeMore = async () => {
  //   try {

  //     console.log(processedImageIndex, visibleRecords);
  //     // Calculate the starting index for the next set of images
  //     const startIndex = processedImageIndex + visibleRecords;
  //     console.log(startIndex, images);
  //     // If startIndex exceeds the total number of images, reset to 0
  //     if (startIndex >= images.length) {
  //       processedImageIndex = 0;
  //     } else {
  //       processedImageIndex = startIndex;
  //     }

  //     await getNewRecordData(startIndex); // Call getNewRecordData to process the next set of images
  //     setVisibleRecords(visibleRecords + 10);

  //   } catch (error) {
  //     console.error('Error in handleClickSeeMore:', error);
  //   }
  // };




  // const getNewRecordData = async (loadedImages) => {
  //   try {
  //     setLoading(true);

  //     let newRecord;

  //     if (userData.role_id === 4) {
  //       newRecord = await API.getNewKaizenData(userData);
  //     } else if (userData.role_id === 5 || userData.role_id === 3) {
  //       newRecord = await API.getNewKaizenDataWithPlantId(userData);
  //     } else {
  //       newRecord = await API.getOverallKaizenData();
  //     }

  //     if (newRecord) {
  //       newRecord.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  //       images = [];

  //       newRecord.forEach(item => {
  //         if (item.before_status && item.before_status.length > 0) {
  //           const beforeImages = JSON.parse(item.before_status[0].before_image);
  //           beforeImages.forEach(image => {
  //             images.push({ image_name: image.image_name });
  //           });
  //         }
  //       });

  //       console.log("images", images);

  //       processedImageIndex = loadedImages ? loadedImages : processedImageIndex;

  //       // Calculate the range of images to pass to the API
  //       const imagesToProcess = images.slice(processedImageIndex, processedImageIndex + 10);
  //       console.log("Downloading images:", imagesToProcess); // Added logging
  //       console.log(processedImageIndex, loadedImages)
  //       // Download blob images and extract data_url
  //       const response = await BlobAPI.downloadBlobImage(imagesToProcess, "before");
  //       console.log("Response from BlobAPI:", response); // Added lo
  //       // Extract data_url from response and update before_status
  //       const dataURLs = response.map((res, index) => ({
  //         image_name: imagesToProcess[index].image_name,
  //         data_url: res.data_url
  //       }));
  //       console.log("Extracted Data URLs:", dataURLs);  

  //       const allProcessedImageData = prevImages => [...prevImages, ...dataURLs];
  //       const processedData = allProcessedImageData(allProcessedImages).length === 0 ? dataURLs : allProcessedImageData(allProcessedImages);

  //     setAllProcessedImages(processedData);
  //       newRecord.forEach(item => {
  //         if (item.before_status && item.before_status.length > 0) {
  //           const beforeStatus = item.before_status[0];
  //           const beforeImages = JSON.parse(beforeStatus.before_image);

  //           beforeImages.forEach((image) => {
  //             const imageData = processedData.find(data => data.image_name === image.image_name);
  //             if (imageData) {
  //               image.data_url = imageData.data_url;
  //             }
  //           });
  //           beforeStatus.before_image = beforeImages;
  //         }
  //       });

  //       setRecordData(newRecord);
  //       return newRecord;
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const handleClickSeeMore = async () => {
  //   try {

  //     console.log(processedImageIndex, visibleRecords);
  //     // Calculate the starting index for the next set of images
  //     const startIndex = processedImageIndex + visibleRecords;
  //     console.log(startIndex, images);
  //     // If startIndex exceeds the total number of images, reset to 0
  //     if (startIndex >= images.length) {
  //       processedImageIndex = 0;
  //     } else {
  //       processedImageIndex = startIndex;
  //     }

  //     await getNewRecordData(startIndex); // Call getNewRecordData to process the next set of images
  //     setVisibleRecords(visibleRecords + 10);

  //   } catch (error) {
  //     console.error('Error in handleClickSeeMore:', error);
  //   }
  // };



  const handleClick = (event) => {
    navigate(`/beforekaizen`, { state: { selectedRecordData: event } });
  };

  const displayedRecords = Array.isArray(recordData)
    ? recordData.slice(0, visibleRecords)
    : [];
  const totalRecords = Array.isArray(recordData) ? recordData.length : 0;


  // Function to filter the records
  const filterRecords = (records, searchText) => {
    return records.filter(record => {
      const kaizenTypeMatch = ((record.kaizen_type ? record.kaizen_type : "") || '').toLowerCase().includes(searchText.toLowerCase());
      const serialNumberMatch = (record.serial_number ? record.serial_number : "").toLowerCase().includes(searchText.toLowerCase());
      const openingDateMatch = ((record.opening_date ? record.opening_date : "") || '').includes(searchText);
      const userFirstNameMatch = ((record.user_first_name ? record.user_first_name : "") || '').toLowerCase().includes(searchText.toLowerCase());
      return serialNumberMatch || kaizenTypeMatch || openingDateMatch || userFirstNameMatch;
    });
  };

  // Function to update filteredSearchRecords
  const updateFilteredSearchRecords = (searchText) => {
    const filteredRecords = filterRecords(recordData, searchText);
    setFilteredSearchRecords(filteredRecords);
  };

  function renderRecords(records) {
    return records.length > 0 ? (
      records.map((record) => {
        const userInitials =
          (record?.user_first_name?.charAt(0) ?? "") +
          (record?.user_last_name?.charAt(0) ?? "");
        return (
          <div key={record.id}>
            <Card
              style={{
                width: "350px",
                maxWidth: 400,
                height: "auto",
                padding: "0px 8px",
                margin: "5px 0",
                backgroundColor: "#f8f8f8",
              }}
              variant="outlined"
            >
              <div
                onClick={() => handleClick(record)}
                style={{
                  width: "350px",
                  maxWidth: 400,
                  height: "auto",
                  padding: "0px 8px",
                  margin: "5px 0",
                  backgroundColor: "#f8f8f8",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => handleClick(record)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginLeft: 10 }}>
                    <Typography
                      style={{
                        color: "#1D1B20",
                        textAlign: "left",
                        fontSize: 16,
                        fontWeight: 580,
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {record.serial_number}
                    </Typography>
                    <Typography
                      style={{
                        color: "#49454F",
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: 12,
                        textTransform: "capitalize",
                      }}
                    >
                      {record.opening_date}
                    </Typography>
                    <Typography
                      style={{
                        color: "#49454F",
                        textAlign: "left",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {record.kaizen_type}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Avatar style={avatarContainerStyle}>
                        {userInitials}
                      </Avatar>
                      <Typography
                        style={{ color: "#49454F", marginLeft: "10px" }}
                      >
                        {record.user_first_name}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* Check if before_status is empty and show placeholder icon */}
                {record.before_status && record.before_status.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {record.before_status[0].before_image &&
                      record.before_status[0].before_image.length > 0 &&
                      record.before_status[0].before_image[0] ? (
                      <>
                        {record.before_status[0].before_image[0].data_url ? (
                          <img
                            src={record.before_status[0].before_image[0].data_url}
                            alt={record.before_status[0].before_image[0].image_name}
                            style={{
                              width: "65px",
                              height: "65px",
                              borderRadius: 5,
                              marginRight: 10,
                            }}
                          />
                        ) : (
                          <img
                            src={PlaceHolderIcon}
                            alt="Placeholder"
                            style={{
                              width: "65px",
                              height: "65px",
                              borderRadius: 5,
                              marginRight: 10,
                            }}
                          />
                        )}

                        {/* Show badge if there are additional images */}
                        {record.before_status[0].before_image.length > 1 && (
                          <p
                            style={{
                              position: "absolute",
                              width: "24px",
                              height: "24px",
                              background: "#00448b",
                              borderRadius: "50%",
                              top: "-6px",
                              right: "4px",
                              fontSize: "14px",
                              padding: "2px 0 0",
                              margin: "0",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            +{record.before_status[0].before_image.length - 1}
                          </p>
                        )}
                      </>
                    ) : (
                      <img
                        src={PlaceHolderIcon}
                        alt="Placeholder"
                        style={{
                          width: "65px",
                          height: "65px",
                          borderRadius: 5,
                          marginRight: 10,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src={PlaceHolderIcon}
                    alt="Placeholder"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: 5,
                      marginRight: 10,
                    }}
                  />
                )}

              </div>
            </Card>
          </div>
        );
      })
    ) : (
      <p>No Kaizens found.</p>
    );
  }

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div style={{ position: 'relative', width: "100%", display: "flex", marginBottom: "10px" }}>
            <img
              src={SearchIcon}
              alt="Search Icon"
              style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
            // onClick={handleSearch}
            />
            <input
              type="text"
              placeholder="Search..."
              style={{ width: '100%', padding: '10px', paddingLeft: '40px', fontSize: "14px", borderRadius: "5px", border: '1px solid #8F908F' }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                updateFilteredSearchRecords(e.target.value);
              }}
            />
          </div>

          {searchText !== null && searchText !== "" ? (
            renderRecords(filteredSearchRecords)
          ) : (
            renderRecords(displayedRecords)
          )}

          {recordData !== null && visibleRecords < recordData.length && (
            <div style={{ marginTop: "20px", display: "flex", flex: 1 }}>
              <Button
                variant="contained"
                style={{
                  width: "200px",
                  backgroundColor: "#00448B",
                  textTransform: "none",
                  fontSize: 24,
                  color: "white",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
                onClick={handleClickSeeMore}

              >
                See More
              </Button>
            </div>
          )}
          <Typography style={{ color: "#000000", fontSize: "25px", fontWeight: 600, marginTop: "10px" }}>
            {visibleRecords <= totalRecords ? `${visibleRecords} of ${totalRecords}` : `${totalRecords} of ${totalRecords}`}
          </Typography>
        </div>
      )}
    </div>
  );

};
export default BeforeKaizenTab;
