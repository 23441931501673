import React from "react";
import { Typography } from "@mui/material";
import { Link as RegLink } from "react-router-dom";
const RouteMissing = () => {
    return (
        <React.Fragment>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15rem",
                }}
            >
                <Typography variant="h4" style={{ fontWeight: "300" }}>
                    &lt;&gt;The page you are looking for is not available.&lt;/&gt;
                </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                    variant="caption"
                    style={{ fontWeight: "300", fontSize: "0.8rem" }}
                >
                    We will look into this immediately to ensure that this does not
                    repeat.
                </Typography>
            </div>
            <div
                style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
            >
                <Typography variant="h5">
                    Click <RegLink to="/">here</RegLink> to go home
                </Typography>
            </div>
        </React.Fragment>
    );
};

export default RouteMissing;
