import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customSelect: {
    "& .MuiSelect-root": {
      color: "#1D1B20",
    },
    "& .MuiInputLabel-root": {
      color: "#49454F",
      padding: '0 8px', // Add some padding to avoid overlapping with outlined border
      backgroundColor: 'white', // Set the background color to match the form control background
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#00448B",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:"#79747E",
      borderWidth: "1px", // You can adjust the thickness as needed
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00448B",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red", // Red border on error
    },
  },
  customSelectEdit: {
    "& .MuiSelect-root": {
      color: "#1D1B20",
    },
    "& .MuiInputLabel-root": {
      color: "#49454F",
      padding: '0 8px', // Add some padding to avoid overlapping with outlined border
      backgroundColor: 'white', // Set the background color to match the form control background
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#00448B",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:"#00448B",
      borderWidth: "3px", // You can adjust the thickness as needed
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00448B",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red", // Red border on error
    },
  },
}));

const CustomSelect = (props) => {
  const { label, value, onChange, list, error, helperText, isKaizenEdit } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedItem = list.find((item) => item[props.id] === selectedValue);
    const selectedName = selectedItem ? selectedItem[props.name] : '';
    onChange(selectedValue, selectedName);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FormControl
        fullWidth
        variant="outlined"
        className={`${isKaizenEdit ? classes.customSelectEdit : classes.customSelect}`}
        error={error}
      >
        <InputLabel shrink>{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange} // Use the handleChange function
        >
          {list.length ? (
            list.map((item) => (
              <MenuItem key={item[props.id]} name={item[props.name]} value={item[props.id]}>
                {item[props.name]}
              </MenuItem>
            ))
          ) : (
            <MenuItem>None</MenuItem>
          )}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};


export default CustomSelect;
