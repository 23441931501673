const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-empowerops.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/chryso";
    default:
      return "http://localhost:3000";
  }
};

const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-empowerops-api.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/api/aim/v2";
    default:
      return "http://localhost:8000/";
  }
};

const _get_auth_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.indec4.saint-gobain.com/auth/";
    case "prod":
      return "https://dc02.saint-gobain.com/auth/";
    default:
      return "https://dev.indec4.saint-gobain.com/auth/";
  }
};
const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_SekuritSmartFactoryMobileapplication&redirect_uri=https://dev-empowerops-api.saint-gobain.com/login&service=simple&scope=sgid";
    case "prod":
      return "https://dc02.saint-gobain.com/aimapi/v1/graphql";
    default:
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_SekuritSmartFactoryMobileapplication&redirect_uri=http://localhost:8000/login&service=simple&scope=sgid";
  }
};


// const _run_rest_api = async (url, data, post, retries = 3) => {
//   let controller = new AbortController();
//   let signal = controller.signal;
//   const myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   setTimeout(() => controller.abort(), 60000);
//   let reqOptions = {
//     method: post ? "POST" : "GET",
//     signal: signal,
//   }
//   let urlLink = _get_auth_url() + url;
//   let urlApp = _get_app_url()
//   if (url === "login") {
//     urlLink += `?app=${urlApp}&tenant_id=fac735fa-66ab-4433-aef0-928c230401fe`;
//   }
//   if (post) {
//     reqOptions.body = JSON.stringify(data);
//     reqOptions.redirect = "follow";
//   } else {
//     let token = localStorage.getItem("SPGCAccessToken");
//     myHeaders.append(
//       "Authorization",
//       "Bearer " + token.replace(/['"]+/g, "")
//     );
//     if (data) {
//       urlLink += "?" + _encode_query_qata(data);
//     }
//   }
//   reqOptions.headers = myHeaders;
//   return fetch(urlLink, reqOptions)
//     .then(response => response.json())
//     .then(result => result)
//     .catch(e => {
//       if (retries > 0) return _run_rest_api(url, data, post, retries - 1);
//       else {
//         promiseSetRecoil(apiTimeout, true);
//         return e;
//       }
//     });
// };
// const setSgId = (id) => localStorage.setItem("UserID", id);
// const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const SGid = function () {
  const UserId = localStorage.getItem("UserID");
  return UserId;
};
export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  API_URL: _get_api_url(),
  SG_ID: SGid(),
  AUTH_URL: _get_auth_url(),
  APP_URL: _get_app_url(),
  SSO_URL : _get_sso_url(),
};

export default configParam;
