import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../assets/icons/ic_empower_logo.svg';
import configParam from "../../Config";
import { makeStyles, Typography, Button } from '@material-ui/core';
import axios from "axios";
import Services from "../../services/Services";
import AlertDialog from "../../input/AlertDialog";

const useStyles = makeStyles(() => ({
  logoTextStyle: {
    height: "44px",
    background: 'linear-gradient(90deg, #00448A 0%, #0D6BA9 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '0em',
    color: "white",
    marginLeft: "10px",
    backgroundColor: "red",
  },
  logoDivStyle: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
}));



const Login = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.replace(url);
  };

  useEffect(() => {
    userDetails()
  }, []);

  const userDetails = async() => {
    const url = window.location.href;
    const Response = decodeURIComponent(url);
    const myArray = Response.split("?");
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      console.log("queryParameters", queryParameters);
      const UserDetails = queryParameters.get("UserData");
      const access_token = queryParameters.get("access_token");
      const secret_key = queryParameters.get("secret_key");
      const err = queryParameters.get("err");
      console.log("error", err);
      const currentUserDetails = JSON.parse(UserDetails);
      console.log("currentUserDetails",currentUserDetails);
      if (currentUserDetails) {
        await getUserDetails(currentUserDetails, access_token, secret_key);
        window.location.href = "/homeDashBoard";
        setLoading(false);
    }
    else {
        localStorage.setItem("userDetails", "");
        setError(err || "Access denied");
        setLoading(false);
    }

    }
  }

  const getUserDetails = async (id, access_token, secret_key) => {
    access_token = access_token.replace(/"/g, "");
    secret_key = secret_key.replace(/"/g, "");
        try {
          const response = await axios.get(`${Services.GET_USER_DATA_FROM_ID}?id=${id}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              secret_key:secret_key
            }
          });
          if (response.status === 200) {
            if (response && response.data) {
              let userData = response.data.user;
              console.log("userDatauserData",userData);
              userData["access_token"] = `${access_token}`;
              userData["secret_key"] = secret_key;  
              localStorage.setItem("userData", JSON.stringify(userData));
            }
          } else {
            console.log("server error");
          }
        } catch (error) {
          if (error.isAxiosError && error.response) {
            const { status } = error.response;
            // Handle specific error codes or messages as needed
            if (status === 429) {
              AlertDialog({
                type: "warning",
                title: "Warning",
                text: "Too Many Requests Submitted, Please try again..",
              });
            }
          } else {
            // Handle other types of errors
            console.log("Other error:", error);
          }
        }
}

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      <div className={classes.logoDivStyle}>
        <img src={Logo} alt="Logo" className="icon" />
        <Typography className={classes.logoTextStyle}>Empower Ops</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          variant="subtitle1"
          style={{ color: "red", marginTop: "15px" }}
        >
          {error}&nbsp;
        </Typography>
        {!loading ? (
          <Button
            variant="contained"
            style={{
              width: "300px",
              backgroundColor: "#00448B",
              textTransform: "none",
              fontSize: 24,
              color: "white",
            }}
            onClick={onHandleSSO}
          >
            Sign in
          </Button>
        ) : (
          <CircularProgress />
        )}
      </div>
      <Typography
        variant="body1"
        style={{ marginTop: 30, fontSize: 14, textAlign: "center" }}
      >
        Copyright © 2023 Saint-Gobain. All Rights reserved
      </Typography>
    </div>
  );

}
export default Login;