
import configParam from "../Config";
const Services = {
    GET_WORK_CENTER_NAME : configParam.API_URL+'work_center_name',
    GET_KAIZEN_TYPE : configParam.API_URL+'kaizen_type',
    GET_KAIZEN_COUNT : configParam.API_URL+'new_kaizen/count',
    IMAGEUPLOAD : configParam.API_URL+'upload',
    GET_DOWNLOAD_IMAGES: configParam.API_URL+'api/download',
    INSERT_NEW_KAIZEN:  configParam.API_URL+'insert/new_kaizen',
    INSERT_BEFORE_STATUS:  configParam.API_URL+'before_status/',
    INSERT_AFTER_STATUS:  configParam.API_URL+'after_status/',
    GET_NEW_KAIZEN:  configParam.API_URL+'get/new_kaizen',
    GET_BEFORE_STATUS_COUNT : configParam.API_URL+'before_status/count',
    GET_AFTER_STATUS_COUNT : configParam.API_URL+'after_status/count',
    GET_USER : configParam.API_URL+'users/',
    UPDATE_BEFORE_STATUS:  configParam.API_URL+'before_status/',
    GET_ROLES:  configParam.API_URL+'roles/',
    GET_BUSINESS:  configParam.API_URL+'business/',
    GET_PLANT:  configParam.API_URL+'plant',
    UPDATE_USER:  configParam.API_URL+'users/',
    UPDATE_AFTER_STATUS:  configParam.API_URL+'after_status/',
    FILE_UPLOAD:  configParam.API_URL+'api/file/upload',
    INSERT_USER:  configParam.API_URL+'insert/users/',
    GET_ALL_USERS : configParam.API_URL+'api/users/',
    DELETE_USER : configParam.API_URL+'api/users/delete',
    GET_DB_DETAILS : configParam.API_URL+'api/db-connection',
    SEND_EMAIL : configParam.API_URL+'send_email',
    GET_ALL_KAIZENS : configParam.API_URL+'all/new_kaizen',
    GET_ALL_USERS_COUNT : configParam.API_URL+'all/users/count',
    GET_WORK_CENTER_NAME_COUNT : configParam.API_URL+'all/work_center_name/count',
    GET_ALL_WORK_CENTERS : configParam.API_URL+'all/work_center_name/',
    GET_BLOB_UPLOAD_IMAGE : configParam.API_URL+'api/blob/upload',
    GET_ALL_PLANTS : configParam.API_URL+'all/plant',
    GET_PLANT_NAME : configParam.API_URL+'get/plant_name',
    GET_WORK_CENTER_NAME_FROM_ID : configParam.API_URL+'get/work_center_name',
    DELETE_BLOB_IMAGE : configParam.API_URL+'api/blob/delete',
    GET_BLOB_DOWNLOAD_IMAGE : configParam.API_URL+'api/blob/download',
    DELETE_NEW_KAIZEN : configParam.API_URL+'delete/new_kaizen',
    UPDATE_NEW_KAIZEN : configParam.API_URL+'update/new_kaizen/',
    GET_KAIZEN_TYPE_NAME_FROM_ID : configParam.API_URL+'get/kaizen_type_name',
    GET_USER_DATA_FROM_ID: configParam.API_URL+'get/user_info',
    GENERATE_PRESENTATION: configParam.API_URL+'download-presentation',
    UPLOAD_PPT: configParam.API_URL+'upload_ppt',
    UPLOAD_PDF: configParam.API_URL+'upload_pdf',
    INSERT_REPORT: configParam.API_URL+'reports',
    INSERT_USER_WORK_CENTER:  configParam.API_URL+'insert/users/work_center/',
    GET_USERS_FOR_PLANT:  configParam.API_URL+'api/users/plant',
    GET_ALL_KAIZENS_WITH_PLANT:  configParam.API_URL+'all/new_kaizen/plant',
    GET_LATEST_KAIZEN:  configParam.API_URL+'latest_kaizen',
    SEND_MAIL_API:  configParam.API_URL+'send_mail_api',

}
export default Services;