import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { imageType } from "../../recoil/atoms";
import { useLocation, useNavigate } from 'react-router-dom';
import CropIcon from '../../assets/icons/ic_tick_crop.svg';
import CloseIcon from '../../assets/icons/ic_close_crop.svg';
import IconButton from '@mui/material/IconButton';


const ImageCropper1 = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate  = useNavigate();
  const [imageValue,setimageValue] = useRecoilState(imageType);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState(null)  
  const recordData = location.state ? location.state.selectedRecordData : null;
  const status =  localStorage.getItem("kaizen_status");

  const onCropComplete = useCallback(( croppedAreaPixels) => {
       const croppedImageURL = getCroppedImageURL(imageValue,croppedAreaPixels);
      setCroppedImage(croppedImageURL); 
  }, []);

  const handleCloseClick = () => {
    navigate(-1);
  };


  function getCroppedImageURL(imageURL, croppedAreaPixels) {
    // Step 1: Load the original image
    const image = new Image();
    image.src = imageURL;
  
    // Step 2: Create a canvas and draw the cropped area on it
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    context.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );
  
    // Step 3: Convert canvas content to a data URL (cropped image URL)
    const croppedImageURL = canvas.toDataURL(); // By default, it will return a PNG image
    // Step 4: Use the cropped image URL as needed (e.g., set it to an <img> element)
    return croppedImageURL;
  }
  

  const handleCropClick = () => {
    setimageValue(croppedImage);
   if(status === "before"){
    navigate(`/capturedimage`, { state: { selectedRecordData: recordData } })
   }else{
    navigate(`/aftercapturedimage`, { state: { selectedRecordData: recordData } })
   }
  }
   
  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={imageValue}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <div style={{padding:15}}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        {/* <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div> */}
       
        <div style={{display:'flex', justifyContent:'space-between', padding:"15px"}}>
        <IconButton onClick={handleCloseClick}>
        <img src={CloseIcon}></img>
        </IconButton>
        <IconButton onClick={handleCropClick}>
         <img src={CropIcon}></img>
        </IconButton>
      </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  cropContainer: {
    position: 'relative',
    height: '50vh',
  },
  viewer: {
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  output: {
    position: 'relative',
    width: '300px',
    overflow: 'hidden',
    boxShadow: '0 0 32px rgba(0, 0, 0, 0.3)',
  },
  outputImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top left',
  },
}));


export default ImageCropper1;
