import React from 'react';
import './PDFForm.css'; // Import the CSS file
import { Typography } from '@mui/material';
const PDFForm = ({ parsedData, parsedAfterData, kaizenType, reportData, beforeDescription, afterDescription}) => {
    console.log("beforeDescriptionbeforeDescription",beforeDescription);
    const firstLetter = kaizenType.charAt(0);
    const firstTwoLetters = kaizenType.substring(0, 2);

    return (
        <div>
            <div className="header_center_align">
                <Typography variant="h5" className="title">EMPOWER OPS - SEKURIT BUSINESS</Typography>
            </div>
            <div style={{ justifyContent: 'space-between', display: "flex" }}>
                <Typography className="title1">{reportData.kaizen_name}</Typography>
                 <table border="1" style={{marginRight:"10px", width:"300px", height:"60px"}}>
                    <tr>
                        <td className='td_style blue-text'>P </td>
                        <td className='td_style blue-text'>Q</td>
                        <td className='td_style blue-text'>C</td>
                        <td className='td_style blue-text'>D</td>
                        <td className='td_style blue-text'>S</td>
                        <td className='td_style blue-text'>M</td>
                        <td className='td_style yellow-text'>S</td>
                    </tr>
                    <tr>
                        <td className='td_style'>{firstLetter === 'P' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstLetter === 'Q' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstLetter === 'C' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstLetter === 'D' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstTwoLetters === 'Sa' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstLetter === 'M' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                        <td className='td_style'>{firstTwoLetters === 'St' ? <span style={{ color: 'black' }}>✓</span> : ''}</td>
                    </tr>
                </table>

            </div>

            <div className="grid-container-wrapper">
                <div className="grid-container">
                    <div className="left-grid">
                        {/* Content for the left grid */}
                        {parsedData.map((item, index) => (
                            <div key={item.id} className="grid-item">
                            <div className="card">
                                <img src={item.data_url} alt={`Image ${index}`} className="card-image"/>
                            </div>
                            </div>
                        ))}
                    </div>
                    <div className="right-grid">
                        {/* Content for the right grid */}
                        {parsedAfterData.map((item, index) => (
                            <div key={item.id} className="grid-item">
                            <div className="card">
                                <img src={item.data_url} alt={`Image ${index}`} className="card-image"/>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="grid-container_bottom">
                <div className="left-grid_bottom">
                    <div className="box">
                        <Typography className="centered-text">Before Kaizen</Typography>
                    </div>
                    <div className="box_description">
                        <Typography className="left-text">Observation</Typography>
                        <Typography className="left-text">{beforeDescription}</Typography>

                    </div>
                </div>
                <div className="right-grid_bottom">
                    <div className="box">
                        <Typography className="centered-text">After Kaizen</Typography>
                    </div>
                    <div className="box_description_right">
                        <Typography className="right-text">Improvement</Typography>
                        <Typography className="right-text">{afterDescription}</Typography>
                    </div>
                </div>


            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', height:"60px", marginRight:"10px", }}>
            <table border="1" style={{width:"300px", marginTop:"10px" }}>
            <tbody>
                <tr>
                <td style={{ padding: "10px", fontSize: "18px", whiteSpace: "nowrap" }}>Kaizen No</td>
                <td style={{padding:"10px", fontSize:"18px"}}>{reportData.serial_number}</td> {/* Replace 'kaizenNumber' with the actual value */}
                </tr>
          </tbody>
        </table>
        </div>
        </div>
    );
};

export default PDFForm;

