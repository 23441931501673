import React, { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@mui/material";
import "./App.css";
import routes from "./routes.js";
import Login from "./pages/login/Login";
import { AuthContext } from "./Context";
import RouteMissing from "./pages/RouteMissing";
import { RecoilRoot } from "recoil";
const SpgcTemplate = lazy(() => import("./app/SpgcTemplate"));


export const switchRoutes = (
  <>
    {routes.mainRoutes.map((route) => {
      return route.component ? (
        <Route path={route.path} name={route.name} element={<route.component />} key={route.name} />
      ) : null;
    })}
    <Route path="/" element={<Navigate replace to="/login" />} />
  </>
);


const PrivateOutlet = () => {
  const currentUser = localStorage.getItem("userData");
  const user = JSON.parse(JSON.stringify(currentUser));
  return user ?<RecoilRoot><SpgcTemplate /></RecoilRoot>  : <Navigate to="/login" />;
};



function App() {
  const currentUser = localStorage.getItem("userData");
  const user = JSON.parse(JSON.stringify(currentUser));

  return (
    <div>
      <AuthContext.Provider value={{ user, setAuthTokens: user }}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path ="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
        </AuthContext.Provider>
    </div>
  );

}

// for my reference 
// Roles
// 1.Admin
// 2.Plant Manager
// 3.Shift Manager
// 4.Operator 
// 5.WCM Coordinator

// Plants
// 1.Chennai
// 2.Chakan
// 3.Bhiwadi

export default App;
