import React, { useState } from 'react';
import { Paper, Popover, Button } from '@material-ui/core';
import { Typography } from '@mui/material';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
const DocumentViewer = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>

      <diV style={{ display: 'flex', padding: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Upload from Gallery</Typography>
      </diV>
      <diV style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Camera</Typography>
      </diV>
    </div>
  );
  return (
    <div style={{ marginTop: 100 }}>

      <Button onClick={handleClick}>Open Popover</Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight, left: 0 }} // update anchorPosition here
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper style={{ width: '100%' }}>{popoverContent}</Paper> {/* add style prop here */}
      </Popover>
    </div>
  );
};

export default DocumentViewer;
