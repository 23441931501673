import Services from "./Services";
import axios from 'axios';
import AlertDialog from "../input/AlertDialog";
import imageCompression from 'browser-image-compression';


const BlobAPI = {
    async uploadBlobImageAPI(imageName, imageBase64, imageType,userData) {
        try {
          const access_token = userData.access_token;
          const secret_key = userData.secret_key;   
            const compressedImage = await this.compressImage(imageBase64);
            const base64Data = compressedImage.split(",")[1];
            const response = await axios.post(Services.GET_BLOB_UPLOAD_IMAGE, {
                imageName,
                image: base64Data,
                imageType,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`, // Assuming access_token is available
                secret_key: secret_key, // Assuming secret_key is available
              },
            }
            );
            console.log("responseeee", response);
            return response;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (!error.response) {
              // Handle network-related errors
              AlertDialog({
                type: 'error',
                title: 'Blob API1',
                text: 'Network error. Please check your internet connection.',
              });
            } else {
              // Handle other Axios errors
              console.error('Axios Error:', error);
              AlertDialog({
                type: 'error',
                title: 'Blob API2',
                text: `Axios error: ${error.message}`,
              });
            }
          } else {
            // Handle other errors
            console.error('Error:', error.message);
            AlertDialog({
              type: 'error',
              title: 'Blob API3',
              text: `Error: ${error.message}`,
            });
          }
        }
    },

    async convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onloadend = function () {
            resolve(reader.result);
          };
      
          reader.onerror = function (error) {
            reject(error);
          };
      
          reader.readAsDataURL(file);
        });
      },
      
      async base64ToBlob(base64String) {
        try {
          // Remove the data:image/png;base64, prefix if it exists
          const base64WithoutPrefix = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
      
          // Convert base64 to binary
          const binaryString = atob(base64WithoutPrefix);
      
          // Create a Uint8Array from the binary string
          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }
      
          // Create a Blob from the Uint8Array
          return new Blob([byteArray], { type: 'image/png' }); // Adjust the 'type' accordingly
        } catch (error) {
          console.error('Error converting base64 to Blob:', error);
          AlertDialog({
            type: 'error',
            title: 'base64ToBlob',
            text: error,
          });
          throw error;
        }
      },
      
      async compressImage(base64String){
        try {
          const options = {
            maxSizeMB: 1, // Specify the maximum size of the compressed image
            maxWidthOrHeight: 800, // Specify the maximum width or height of the compressed image
            useWebWorker: true,
          };
      
          // Convert base64 to Blob
          const blob = await this.base64ToBlob(base64String);
      
          // Compress the image using browser-image-compression
          const compressedFile = await imageCompression(blob, options);
      
          // Convert the compressed file back to base64
          const compressedBase64 = await this.convertFileToBase64(compressedFile);
      
          return compressedBase64;
        } catch (error) {
          console.error('Error compressing image:', error);
          AlertDialog({
            type: 'error',
            title: 'compressImage',
            text: error,
          });
          throw error;
        }
      },
    
    async deleteBlobImageAPI(imageName, userData) {
        console.log("imageNameimageName", imageName);
        const access_token = userData.access_token;
        const secret_key = userData.secret_key;   
        try {
          const response = await axios.delete(`${Services.DELETE_BLOB_IMAGE}/${imageName}`, {
            headers: {
              Authorization: `Bearer ${access_token}`, // Assuming access_token is available
              secret_key: secret_key // Assuming secret_key is available
            }
          });
        console.log("response for delete image", response);
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    },

    async downloadBlobImage(images, image_count,userData) {
      const access_token = userData.access_token;
      const secret_key = userData.secret_key;   

      try {
        const response = await axios.get(Services.GET_BLOB_DOWNLOAD_IMAGE, {
          params: {
            images: images,
            image_count : image_count
          },
            headers: {
              Authorization: `Bearer ${access_token}`, // Assuming access_token is available
              secret_key: secret_key // Assuming secret_key is available
            }
        });
        // Check if the response status is OK (200)
        if (response.status === 200) {
          return response.data;
        } else {
          // Handle non-200 responses
          throw new Error('Image download failed. Status: ' + response.status);
        }
      } catch (error) {
        console.error('Error downloading image:', error);
        throw error;
      }
    },

    
    async insertBeforeStatusAPI(recordId, beforeImage, description, userData) {
        const formData = new FormData();
        formData.append('before_image', JSON.stringify(beforeImage));
        formData.append('created_at', new Date().toISOString());
        formData.append('updated_at', new Date().toISOString());
        formData.append('date', new Date().toISOString());
        formData.append('description', description);
        formData.append('kaizen_id', recordId);
        formData.append('user_id', userData.id);
      
        const response = await axios.post(Services.INSERT_BEFORE_STATUS + recordId, formData);
        return response.data;
      }
      
}

export default BlobAPI;