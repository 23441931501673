import { atom } from 'recoil';

export const apiTimeout = atom({
  key: "apiTimeout",
  default: false,
});

export const drawerMode = atom({
  key: 'drawerMode',
  default: false
});
export const numberStatus = atom({
  key: 'serialNumber',
  default: false
});
export const menuChange = atom({
  key: 'menuChange',
  default: false
});
export const isUpdateBeforeStatus = atom({
  key: 'update',
  default: false
});
export const isUpdateAfterStatus = atom({
  key: 'updateAfter',
  default: false
});
export const themeMode = atom({
    key: 'themeMode',
    default: 'light'
  });
  export const menuList = atom({
    key: 'menuList',
    default: 'Home'
  });
  export const imageType = atom({
    key: "imageType",
    default: '',
  });
  export const afterImageType = atom({
    key: "afterImageType",
    default: '',
  });
  export const fileName = atom({
    key: "fileName",
    default: '',
  });
  export const beforeImageData = atom({
    key: "beforeImageData",
    default: '',
  });
  export const recordInfo = atom({
    key: "recordInfo",
    default: '',
  });
  export const reportData = atom({
    key: "reportData",
    default: '',
  });
  export const beforeImageDescriptionState = atom({
    key: 'beforeImageDescriptionState',
    default: '', // Set the initial value to an empty string
  });
  
  export const afterImageDescriptionState = atom({
    key: 'afterImageDescriptionState',
    default: '', // Set the initial value to an empty string
  });
  export const kaizenDataUpdateState = atom({
    key: 'kaizenDataUpdateState',
    default: '', // Set the initial value to an empty string
  });

  export const newKaizenUpdatestate = atom({
    key: 'newKaizenUpdateState',
    default: {}, // Set the initial value to an empty string
  });

  