import React, { useState } from "react";
import {
  Button,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useRef,useEffect} from "react";
import { Typography, } from "@material-ui/core";
import { useLocation, } from "react-router-dom";
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import { Paper, Popover } from '@material-ui/core';
import EmailPopup from '../../input/EmailPopup';
import ImageSlider from "../../input/ImageSlider";
import PDFForm from "./PDFForm";
import Services from "../../services/Services";
import axios from "axios";
import Text from '../../input/Text';

const ReportsFinalPage = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [previewForm, setPreviewForm] = useState(false);
  const [beforeDescription, setBeforeDescription] = useState('');
  const [afterDescription, setAfterDescription] = useState('');
  const getReportData = location.state ? location.state.seletedReportData : null;
  const [plantName] = useState(getReportData ? getReportData.plant_name : '');
  const [workCenterName] = useState(getReportData ? getReportData.work_center_name : '');
  const [kaizenTypeName] = useState(getReportData ? getReportData.kaizen_type : '');
  const [parsedData, setParsedData] = useState([]);
  const [parsedAfterData, setParsedAfterData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  let beforeData, afterData;
 

  useEffect(() => {
    if (getReportData) {
      beforeData = getReportData?.before_status?.[0];
      const parsedData = beforeData?.before_image || [];
      setBeforeDescription(getReportData.observation);
      setParsedData(parsedData);


      afterData = getReportData.after_status?.[0];
      const parsedAfterData = afterData?.after_image || [];
      setParsedAfterData(parsedAfterData);
      setAfterDescription(afterData.description);
    }
  }, [getReportData]);


  const printReport = () => {
    setAnchorEl(event.currentTarget);
  }

  const handlePDF = () => {
    setPreviewForm(true);
    printCover();
    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePPT = () => {
    handlePPTDownload(getReportData);
    handleClose();
  };

  const handlePPTDownload = async (reportData) => {
    const access_token = userData.access_token;
    const secret_key = userData.secret_key;
    const formData = new FormData();
    formData.append('kaizen_name', reportData.kaizen_name);
    formData.append('kaizen_type', kaizenTypeName);
    formData.append('serial_number', reportData.serial_number);
    formData.append('before_description', beforeDescription);
    formData.append('after_description', afterDescription);
    formData.append('before_image_array', JSON.stringify(parsedData));
    formData.append('after_image_array', JSON.stringify(parsedAfterData));
    const response = await axios.post(Services.UPLOAD_PPT, formData, {
      headers: {
        Authorization: `Bearer ${access_token}`, // Assuming access_token is available
        secret_key: secret_key // Assuming secret_key is available
      }
    });  
    if (response.status === 200) {
      // Convert response data to ArrayBuffer
      console.log("responseee", response.data.filePath);
      const pptDownloadLink = response.data.filePath;
      // Create a temporary anchor element
      const downloadAnchor = document.createElement("a");
      downloadAnchor.href = pptDownloadLink;
      downloadAnchor.download = "presentation.pptx"; // Set the desired file name
      // Trigger a click event on the anchor element
      downloadAnchor.click();
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the delay as needed
      // Clean up by removing the temporary anchor element
      if (document.body.contains(downloadAnchor)) {
        document.body.removeChild(downloadAnchor);
      }
    } else {
      // Handle any error responses here
      console.error('Error downloading PPT:', response.statusText);
    }


  }

  const popoverContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>

      <div onClick={handlePPT} style={{ display: 'flex', padding: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Download PPT</Typography>
      </div>
      <div onClick={handlePDF} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Download PDF</Typography>
      </div>
    </div>
  );


  const handleShareClick = () => {
    if (!anchorE2) {
      setAnchorE2(document.body);
    }   
    // navigate(`/presentation`);
    // navigate(`/presentation`, { state: { seletedReportData: getReportData } });
   }
  

  const portraitOrientation = () => {
    return (
      <style type="text/css">
        {"@media print{@page {size: landscape; margin: 5mm}}"}
      </style>
    )
  }
  
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const printCover = () => {
    setTimeout(() => {
      handlePrint();
    }, 400);
  }

  const handleCloseEmailPopup = () => {
    setAnchorE2(null);
  }


  return (
    <div style={{ padding: 10 }}>
      <div>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Reports</Typography>
        <Typography style={{ textAlign: 'left', fontSize: "14px" }}>Overall, Sekurit India took and finished a Kaizen.</Typography>
      </div>

      <div>
        <div style={{ marginTop: "30px" }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Name"
            value={getReportData.kaizen_name}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ marginTop: 30, }}>
          <Text
            style={{ width: "100%", textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Location"
            value={plantName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Work Center"
            value={workCenterName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Serial Number"
            value={getReportData.serial_number}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: 'left' }}
            id="outlined-read-only-input"
            label="Kaizen Type"
            value={kaizenTypeName}
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30 }}>
          <Text
            style={{ width: "100%", marginTop: 30, textAlign: "left" }}
            id="date"
            label="Date"
            type="date"
            variant="outlined"
            value={getReportData.opening_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>Before Kaizen</Typography>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 16, fontWeight: 600 }}>Observations</Typography>
        <Typography style={{ textAlign: 'left' }}>{getReportData.observation}</Typography>
        <div style={{marginTop:"30px"}}>
        <ImageSlider
           images={parsedData.map(image => image.data_url)}
          />
        </div>
      </div>

      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600 }}>After Kaizen</Typography>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 16, fontWeight: 600 }}>Improvements</Typography>
        <Typography style={{ textAlign: 'left' }}>{afterDescription}</Typography>
        <div style={{marginTop:"30px"}}>
        <ImageSlider
           images={parsedAfterData.map(image => image.data_url)}
          />
        </div>
      </div>

      <div style={{ margin: 'auto', width: "350px", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            style={{
              width: "45%",
              borderRadius: '3px',
              borderWidth: '3px',
              border: '2px solid #00448B',
              fontSize: 20,
              color: "#00448B",
              marginTop: "10px",
              textTransform: 'none',
              marginRight: 30,
              height: 40,
            }}
            onClick={printReport}
          >
            Print Report
          </Button>

          <div style={{ marginTop: 100 }}>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: window.innerHeight, left: 0 }} // update anchorPosition here
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Paper style={{ width: '100%' }}>{popoverContent}</Paper> {/* add style prop here */}
            </Popover>
          </div>


    <Button
      variant="contained"
      style={{
        width: "45%",
        borderRadius: '3px',
        backgroundColor: "#00448B",
        fontSize: 20,
        color: "white",
        marginTop: "10px",
        textTransform: 'none',
        height:40,
      }}
      onClick={handleShareClick}
    >
      Share
    </Button>
    {anchorE2 && <EmailPopup anchorE2={anchorE2} setAnchorE2={setAnchorE2} reportData ={getReportData} closePopup={handleCloseEmailPopup}/>}
  </div>
</div>
        {
                previewForm ? 
                    <>
                        <div id="cust-print" style={{display: "none", marginTop: 20 }}>
                            <div ref={printRef} style={{
                                margin: "10px",
                                width: "1150px"
                            }}>
                                <style>{portraitOrientation()}</style>
                                <PDFForm parsedData={parsedData} parsedAfterData={parsedAfterData} kaizenType={kaizenTypeName} reportData={getReportData} beforeDescription={beforeDescription} afterDescription ={afterDescription}/>
                            
                            </div>
                        </div>
                    </>
                    :
                    <></>
            }
      
    </div>
  )
}
export default ReportsFinalPage;
