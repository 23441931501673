import {
  Typography
} from "@mui/material";
import React, { useEffect, useState, } from "react";
import BeforeImageIcon from '../../assets/icons/ic_before_image.svg';
import Card from "@material-ui/core/Card";
import API from "../../services/API";
import AdminDashboard from "./AdminDashBoard";

const HomeDashBoard = () => {
  const [totalKaizenCount, setTotalKaizenCount] = useState(0);
  const [beforeStatusCount, setBeforeStatusCount] = useState(0);
  const [afterStatusCount, setAfterStatusCount] = useState(0);
  const [workCenterCountOperator, setWorkCenterCountOperator] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"));

  console.log("userDataaaa", userData);

  useEffect(() => {
    if (userData.role_id === 4) {
      getTotalKaizenCount();
      getWorkCenterCount();
    }
  }, [userData.role_id]);


  const getTotalKaizenCount = async () => {
    try {
      const newRecord = await API.getNewKaizenData(userData);
      if (newRecord?.length) {
        let totalBeforeStatusCount = 0;
        let totalAfterStatusCount = 0;

        newRecord.forEach(kaizen => {
          if (Array.isArray(kaizen.before_status) && kaizen.before_status.length > 0) {
            totalBeforeStatusCount += kaizen.before_status.length;
          }

          if (kaizen.after_status) {
            totalAfterStatusCount += kaizen.after_status.length;
          }
        });

        setTotalKaizenCount(newRecord.length);
        setBeforeStatusCount(totalBeforeStatusCount);
        setAfterStatusCount(totalAfterStatusCount);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const getWorkCenterCount = async () => {
    const response = await API.getWorkCenterCountWithPlantId(userData);
    setWorkCenterCountOperator(response);
  }
  return (
    <div style={{ padding: 20 }}>
      <div style={{ marginTop: 20 }}>
        <Typography style={{ textAlign: 'left', color: 'black', fontSize: 20, fontWeight: 600, marginTop: "20px" }}>Home Dashboard</Typography>
        <Typography style={{ textAlign: 'left' }}>Overall Kaizen taken and completed in Sekurit India </Typography>
      </div>
      {userData.role_id === 4 ? ( // Check the role_id and conditionally render based on the condition
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <Card style={{ alignItems: 'center', width: 200, height: 144, backgroundColor: '#E8E8E8', borderRadius: 4, marginRight: 15 }} variant="outlined">
              <img src={BeforeImageIcon} alt="Add Icon" style={{ margin: 10 }} />
              <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>{totalKaizenCount}</Typography>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', fontWeight: 600, fontSize: 18, textTransform: 'capitalize', marginTop: 5 }}>Total Kaizen</Typography>
            </Card>
            <Card style={{ alignItems: 'center', width: 200, height: 144, backgroundColor: '#E8E8E8', borderRadius: 4 }} variant="outlined">
              <img src={BeforeImageIcon} alt="Add Icon" style={{ margin: 10 }} />
              <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>{workCenterCountOperator}</Typography>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', fontWeight: 600, fontSize: 18, textTransform: 'capitalize', marginTop: 5 }}>Work Center</Typography>
            </Card>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <Card style={{ alignItems: 'center', width: 200, height: 144, backgroundColor: '#E8E8E8', borderRadius: 4, marginRight: 15 }} variant="outlined">
              <img src={BeforeImageIcon} alt="Add Icon" style={{ margin: 10 }} />
              <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>{beforeStatusCount}</Typography>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', fontWeight: 600, fontSize: 18, textTransform: 'capitalize', marginTop: 5 }}>Before Kaizen</Typography>
            </Card>
            <Card style={{ alignItems: 'center', width: 200, height: 144, backgroundColor: '#E8E8E8', borderRadius: 4 }} variant="outlined">
              <img src={BeforeImageIcon} alt="Add Icon" style={{ margin: 10 }} />
              <Typography style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>{afterStatusCount}</Typography>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', fontWeight: 600, fontSize: 18, textTransform: 'capitalize', marginTop: 5 }}>After Kaizen</Typography>
            </Card>
          </div>
        </div>
      ) : null}

      {userData.role_id === 1 || userData.role_id === 2 || userData.role_id === 5 || userData.role_id === 3 ? (
        <div>
          <AdminDashboard />
        </div>
      ) : null}
    </div>

  );
}
export default HomeDashBoard;